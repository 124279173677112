<template>
  <el-form>
    <el-form-item style="margin-bottom: 20px">
      <span style="margin-right: 10px">Choose Product Type</span>
      <el-select @change="toComponent" v-model="node.content.options.msg_type" placeholder="Select">
        <el-option
          v-for="(item, index) in message_type"
          :key="index"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <WhatsAppSingleProduct v-if="selectedType === 'whatsapp_single_product'" :node="node" />
    <WhatsAppMultiProduct v-else-if="selectedType === 'whatsapp_multi_product'" :node="node" />
  </el-form>
</template>

<script>
import WhatsAppSingleProduct from "@/components/WhatsAppSingleProduct";
import WhatsAppMultiProduct from "@/components/WhatsAppMultiProduct";

export default {
  name: "WhatsAppProductMessage",
  components: {
    WhatsAppSingleProduct,
    WhatsAppMultiProduct,
  },
  props: ["node"],
  data() {
    return {
      message_type: [
        {
          label: "Single Product",
          value: "whatsapp_single_product",
        },
        {
          label: "Multi Product",
          value: "whatsapp_multi_product",
        },
      ],
    };
  },
  computed: {
    selectedType() {
      return this.node.content.options.msg_type;
    },
  },
  methods: {
    toComponent() {
      if (this.selectedType === "whatsapp_single_product") {
        this.node.content = {
          ...this.node.content,
          options: {
            footer: "",
            catalog_id: "",
            product_retailer_id: "",
            msg_type: "whatsapp_single_product",
          },
        };
      } else if (this.selectedType === "whatsapp_multi_product") {
        this.node.content = {
          ...this.node.content,
          options: {
            header: {
              type: "text",
            },
            footer: "",
            action: {
              catalog_id: "",
              sections: [],
            },
            msg_type: "whatsapp_multi_product",
          },
        };
      }
    },
  },
};
</script>

<style></style>
