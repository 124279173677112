<template>
  <el-container>
    <el-main>
      <el-row :gutter="20" style="display: flex">
        <div>
          <div>
            <el-select
              v-model="selectedMessageTemplate"
              placeholder="Select a message template"
              @change="handleViewMessageTemplateChange"
              filterable
            >
              <el-option
                v-for="item in uniqueMessageTemplates"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
            <el-select
              style="margin-left: 5px"
              ref="availableMessageTemplateLanguagesDropdown"
              v-model="selectedMessageTemplateLanguage"
              placeholder="Select a language"
              @change="handleViewMessageTemplateLanguageChange"
              filterable
            >
              <el-option
                v-for="language in availableMessageTemplateLanguages"
                :key="language"
                :label="language"
                :value="language"
              />
            </el-select>
            <el-button
              style="margin-left: 10px"
              type="primary"
              icon="el-icon-plus"
              @click="addNewTemplate"
              >New Template</el-button
            >
            <el-button
              :disabled="!categoryIsSupported"
              type="primary"
              v-if="selectedMessageTemplate && selectedMessageTemplateLanguage"
              icon="el-icon-plus"
              @click="addNewLanguage"
              >New Language</el-button
            >
            <el-tooltip
              v-if="
                !categoryIsSupported && selectedMessageTemplate && selectedMessageTemplateLanguage
              "
              content="You cannot add a language because this template category no longer exists. Please create a new template instead."
              placement="bottom-start"
            >
              <i class="el-icon-info" style="margin-left: 10px; color: grey" />
            </el-tooltip>
            <el-tooltip
              content="It refreshes message templates from network instead of cache"
              placement="bottom"
              v-if="showAdvanced"
            >
              <el-button
                type="primary"
                icon="el-icon-refresh"
                @click="refreshTemplate"
                circle
                plain
                :loading="refreshingMessageTemplate"
              ></el-button>
            </el-tooltip>
            <el-button :disabled="true" plain type="success"
              >Graph API {{ graphAPIVersion }}</el-button
            >
            <el-tooltip
              content="Graph API version can be configured in Settings > Whatsapp > Graph API Version"
              placement="right"
            >
              <i class="el-icon-info" style="margin-left: 10px; color: grey" />
            </el-tooltip>
          </div>
          <div style="position: relative; display: flex; justify-content: center"></div>
        </div>
      </el-row>
      <el-row :gutter="20">
        <div>
          <el-dialog title="Preview" :visible.sync="previewDialogVisible" width="50%" center>
            <div style="position: relative; display: flex; justify-content: center">
              <WhatsAppMessageTemplatePreview
                :header="previewHeader"
                :body="previewBody"
                :footer="previewFooter"
                :buttons="previewButtons"
                :button-type="previewButtonType"
              />
            </div>
          </el-dialog>
          <MessageTemplateForm
            :addingNewLanguage="addingNewLanguage"
            :selectedLanguages="selectedLanguages"
            :name="name"
            :language="language"
            :category="category"
            :header="header"
            :body="body"
            :footer="footer"
            :buttons="buttons"
            :button-type="buttonType"
            :status="status"
            :headerExamples="headerExamples"
            :bodyExamples="bodyExamples"
            :read-only="readOnly"
            ref="form"
          >
            <template #footer="slotProps">
              <el-button
                type="primary"
                :loading="addingMessageTemplate"
                @click="addMessageTemplate(slotProps)"
                v-if="!readOnly"
                :disabled="slotProps.warningExists || !slotProps.sampleCompleted"
                >Add Message Template</el-button
              >
              <el-tooltip
                v-if="slotProps.warningExists"
                content="Warning at body input or any button url address invalid or any empty button text found."
                placement="bottom-start"
              >
                <i
                  class="el-icon-info"
                  style="margin-left: 10px; margin-right: 16px; color: grey"
                />
              </el-tooltip>
              <el-button
                type="primary"
                :loading="deletingMessageTemplate"
                @click="deleteMessageTemplate(slotProps)"
                v-if="readOnly"
                >Delete Message Template</el-button
              >
              <el-button type="primary" @click="preview(slotProps)">Preview</el-button>
            </template>
          </MessageTemplateForm>
        </div>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import WhatsAppMessageTemplatePreview from "./WhatsAppMessageTemplatePreview";
import MessageTemplateForm from "./MessageTemplateForm";
import { messageTemplateCategories } from "./messageTemplateConstant";

export default {
  name: "MessageTemplateTab",
  components: {
    WhatsAppMessageTemplatePreview,
    MessageTemplateForm,
  },
  props: {
    messageTemplates: Array,
    wa_endpoint: String,
  },
  data() {
    return {
      name: "",
      language: "",
      category: "",
      header: {},
      body: {},
      footer: {},
      buttons: [],
      buttonType: "",
      status: "",
      headerExamples: [],
      bodyExamples: [],

      previewDialogVisible: false,
      addingMessageTemplate: false,
      previewHeader: {},
      previewBody: {},
      previewFooter: {},
      previewButtons: [],
      previewButtonType: "",

      deletingMessageTemplate: false,
      selectedMessageTemplateLanguage: "",
      selectedMessageTemplate: "",
      readOnly: false,

      refreshingMessageTemplate: false,
      addingNewLanguage: false,
      selectedLanguages: [],
    };
  },
  computed: {
    ...mapGetters(["showAdvanced"]),
    uniqueMessageTemplates() {
      return _.sortedUniqBy(this.messageTemplates, (o) => o.name) || [];
    },
    availableMessageTemplateLanguages() {
      return (
        _.map(
          _.filter(this.messageTemplates, (o) => o.name === this.$data.selectedMessageTemplate),
          (o) => o.language
        ) || []
      );
    },
    graphAPIVersion() {
      return this.$store.state.modules.whatsapp.graphAPIVersion || "v16.0";
    },
    categoryIsSupported() {
      const listOfSupportedCategories = messageTemplateCategories[this.graphAPIVersion]?.map(
        (item) => item.value
      );
      return !!this.category && listOfSupportedCategories.includes(this.category);
    },
  },
  mounted() {},
  methods: {
    async refreshTemplate() {
      this.refreshingMessageTemplate = true;
      await this.$store.dispatch("GET_WHATSAPP_MESSAGE_TEMPLATES", {
        data: { wa_endpoint: this.wa_endpoint },
        refresh: true,
      });
      this.$notify.success({
        title: "Success",
        position: "bottom-right",
        message: `Successfully refreshed message templates.`,
      });
      this.refreshingMessageTemplate = false;
    },
    addNewTemplate() {
      this.selectedMessageTemplate = "";
      this.selectedMessageTemplateLanguage = "";
      this.addingNewLanguage = false;
      this.selectedLanguages = [];
      this.clearCurrentMessageTemplateForm();
      this.$refs.form.$el.scrollIntoView({ behavior: "smooth" });
    },
    addNewLanguage() {
      this.addingNewLanguage = true;
      this.selectedLanguages = _.clone(this.availableMessageTemplateLanguages);
      this.selectedMessageTemplate = "";
      this.selectedMessageTemplateLanguage = "";
      this.language = "";
      this.header = {};
      this.body = {};
      this.footer = {};
      this.buttons = [];
      this.buttonType = "";
      this.readOnly = false;
      this.status = "";
      this.headerExamples = [];
      this.bodyExamples = [];
      this.$refs.form.$el.scrollIntoView({ behavior: "smooth" });
    },
    clearCurrentMessageTemplateForm() {
      this.name = "";
      this.language = "";
      this.category = "";
      this.header = {};
      this.body = {};
      this.footer = {};
      this.buttons = [];
      this.buttonType = "";
      this.readOnly = false;
      this.status = "";
      this.headerExamples = [];
      this.bodyExamples = [];
    },
    deleteMessageTemplate() {
      this.$confirm(
        `You are about to delete message template ${this.selectedMessageTemplate}, including all language(s) that has been created, are you sure?`,
        "Warning",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.deletingMessageTemplate = true;
          this.$store
            .dispatch("DELETE_WHATSAPP_MESSAGE_TEMPLATES", {
              data: {
                name: this.selectedMessageTemplate,
                wa_endpoint: this.wa_endpoint,
              },
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: `Message template successfully deleted!`,
              });
              this.clearCurrentMessageTemplateForm();
              this.deletingMessageTemplate = false;
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: `Failed to delete message template!`,
              });
              this.deletingMessageTemplate = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete cancelled!",
          });
        });
    },
    changeMessageTemplate() {
      this.clearCurrentMessageTemplateForm();
      const messageTemplate = _.find(
        this.messageTemplates,
        (o) =>
          o.language === this.$data.selectedMessageTemplateLanguage &&
          o.name === this.$data.selectedMessageTemplate
      );
      if (messageTemplate) {
        // Track unmodified data to be used in update WHERE clause
        this.readOnly = true;
        const { name, category, language, status, components } = messageTemplate;
        const header = _.find(components, (component) => component.type === "HEADER");
        const body = _.find(components, (component) => component.type === "BODY");
        const footer = _.find(components, (component) => component.type === "FOOTER");

        const buttons = _.find(components, (component) => component.type === "BUTTONS");

        this.name = name;
        this.category = category;
        this.language = language;
        if (header) {
          if (header.format === "TEXT") {
            this.header = {
              type: "TEXT",
              text: header.text,
            };
          } else {
            this.header = {
              type: "MEDIA",
              mediaType: header.format,
            };
          }
        }

        if (body) {
          this.body = {
            text: body.text,
          };
        }

        if (footer) {
          this.footer = {
            text: footer.text,
          };
        }

        if (buttons) {
          const buttonsArr = buttons.buttons;
          const isQuickReplies = _.every(buttonsArr, (button) => button.type === "QUICK_REPLY");
          if (isQuickReplies) {
            this.buttonType = "QUICK_REPLY";
          } else {
            this.buttonType = "CALL_TO_ACTION";
          }
          this.buttons = buttonsArr.map((button) => {
            if (button.type === "URL") {
              const urlButton = {
                ...button,
                url: button.url.replace(/{{(\d+)}}/, ""),
                urltype: /{{(\d+)}}/.test(button.url) ? "DYNAMIC" : "STATIC",
              };
              return urlButton;
            }
            return button;
          });
        }

        if (status) {
          this.status = status;
        }
      }
    },
    handleViewMessageTemplateChange(val) {
      this.selectedMessageTemplateLanguage = "";
      this.changeMessageTemplate();
    },
    handleViewMessageTemplateLanguageChange(val) {
      this.changeMessageTemplate();
    },

    getPreviewButtonType(messageTemplate) {
      if (messageTemplate.buttons.length === 0) return undefined;

      const quickReplyButtons = _.find(
        messageTemplate.buttons,
        (button) => button.type === "QUICK_REPLY"
      );
      if (quickReplyButtons) {
        return "QUICK_REPLY";
      }
      const callToActionButtons = _.find(
        messageTemplate.buttons,
        (button) => button.type === "PHONE_NUMBER" || button.type === "URL"
      );
      if (callToActionButtons) {
        return "CALL_TO_ACTION";
      }

      return undefined;
    },
    preview(messageTemplate) {
      this.previewButtonType = this.getPreviewButtonType(messageTemplate);
      this.previewButtons = messageTemplate.buttons;
      this.previewBody = messageTemplate.body;
      this.previewFooter = messageTemplate.footer;
      this.previewHeader = {
        format:
          messageTemplate.header.type === "TEXT"
            ? messageTemplate.header.type
            : messageTemplate.header.mediaType,
        text: messageTemplate.header.text,
      };
      this.previewDialogVisible = true;
    },
    addMessageTemplate(messageTemplate) {
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   this.submitStatus = "ERROR";
      // } else {
      // do your submit logic here
      this.addingMessageTemplate = true;
      const messageTemplatePayload = {};

      if (messageTemplate.header) {
        if (messageTemplate.header.type === "TEXT") {
          messageTemplatePayload.header = {
            mediaType: messageTemplate.header.type,
            text: messageTemplate.header.text,
            example: {
              header_text: messageTemplate.headerExamples,
            },
          };
        } else {
          messageTemplatePayload.header = {
            mediaType: messageTemplate.header.mediaType,
            example: {
              header_handle: messageTemplate.headerExamples,
            },
          };
        }
      }

      if (messageTemplate.body && messageTemplate.body.text) {
        messageTemplatePayload.body = messageTemplate.body;
        messageTemplatePayload.body.example = {
          body_text: [messageTemplate.bodyExamples], // it is multi dimensional array as Graph API doc
        };
      }

      if (messageTemplate.footer && messageTemplate.footer.text) {
        messageTemplatePayload.footer = messageTemplate.footer;
      }

      if (Array.isArray(this.buttons) && this.buttons.length > 0) {
        messageTemplatePayload.buttons = this.buttons.map((button) => {
          if (button.type === "PHONE_NUMBER") {
            const phoneButton = {
              ...button,
              phone_number: `${button.country_code}${button.phone_number}`,
            };
            delete phoneButton.country_code;
            delete phoneButton.url;
            delete phoneButton.urltype;
            return phoneButton;
          } else if (button.type === "URL") {
            const urlButton = {
              ...button,
              url: button.urltype === "STATIC" ? button.url : button.url + "{{1}}",
            };
            delete urlButton.country_code;
            delete urlButton.urltype;
            delete urlButton.phone_number;
            return urlButton;
          }
          return button;
        });
      }
      this.$store
        .dispatch("ADD_WHATSAPP_MESSAGE_TEMPLATES", {
          data: {
            ...messageTemplatePayload,
            name: messageTemplate.templateName,
            category: messageTemplate.category,
            language: messageTemplate.language,
            wa_endpoint: this.wa_endpoint,
          },
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: res,
          });
          this.addingMessageTemplate = false;
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err?.response?.data?.error?.message || "Internal Server Error",
          });
          this.addingMessageTemplate = false;
        });
      // }
    },
  },
};
</script>
