<template>
  <el-row class="whatsapp-multi-product">
    <el-col :span="10">
      <el-form>
        <el-form-item>
          <el-input
            type="text"
            v-model="node.content.options.header.text"
            maxlength="60"
            show-word-limit
            placeholder="Content header"
          />
        </el-form-item>

        <el-form-item>
          <el-input
            type="textarea"
            placeholder="Add body text"
            maxlength="1024"
            show-word-limit
            v-model="node.content.text"
            rows="5"
          />
        </el-form-item>

        <el-form-item>
          <el-input
            type="text"
            placeholder="Optional"
            maxlength="60"
            v-model="node.content.options.footer"
            show-word-limit
          >
            <template slot="prepend">Footer</template>
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-input
            type="text"
            placeholder="Required"
            v-model="node.content.options.action.catalog_id"
          >
            <template slot="prepend">Catalog ID</template>
          </el-input>
        </el-form-item>

        <div
          class="interactive-message-section"
          v-for="(section, index) in node.content.options.action.sections"
          :key="index + 1"
        >
          <el-form-item>
            <el-input type="text" maxlength="60" v-model="section.title" show-word-limit>
              <template slot="prepend">Section Title</template>
            </el-input>
          </el-form-item>

          <el-form-item v-for="(product, index) in section.product_items" :key="index">
            <el-input type="text" aria-required="true" v-model="product.product_retailer_id">
              <template slot="prepend"> Product ID </template>
              <template slot="append">
                <el-button
                  type="primary"
                  title="Remove product"
                  @click="removeProduct(section, index)"
                >
                  <i class="el-icon-delete has-text-danger"></i>
                </el-button>
              </template>
            </el-input>
          </el-form-item>

          <div style="margin: 10px 0; text-align: right">
            <el-button type="primary" @click="addProduct(section)">Add Product</el-button>
          </div>

          <button
            v-if="index > 0"
            class="section-delete-button"
            @click.prevent="deleteSection(index)"
          >
            <i class="el-icon-close"></i>
          </button>
        </div>
        <div style="margin: 10px 0">
          <el-button type="primary" @click="addSection">Add Section</el-button>
        </div>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "WhatsAppMultiProduct",
  props: ["node"],
  methods: {
    addSection() {
      this.node.content.options.action.sections.push({
        title: "",
        product_items: [],
      });
    },
    deleteSection(index) {
      this.$confirm("Are you sure you want to remove this section ", "Confirmation", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        cancelButtonClass: "section-delete-btn-cancel",
        confirmButtonClass: "section-delete-btn-confirm",
        type: "warning",
      }).then(() => {
        this.node.content.options.action.sections.splice(index, 1);
      });
    },
    addProduct(section) {
      section.product_items.push({
        product_retailer_id: "",
      });
    },
    removeProduct(section, index) {
      this.$confirm("Are you sure you want to remove this product ", "Confirmation", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        cancelButtonClass: "section-delete-btn-cancel",
        confirmButtonClass: "section-delete-btn-confirm",
        type: "warning",
      }).then(() => {
        section.product_items.splice(index, 1);
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/colors.scss";

.interactive-message-section {
  border: 1px solid #cecece;
  padding: 0px 25px;
  padding-top: 25px;
  padding-bottom: 15px;
  margin: 10px 0;
  border-radius: 5px;
  position: relative;
}

.section-delete-button {
  position: absolute;
  right: 6px;
  top: 6px;
  border: 0;
  padding: 0;
  background: #fff;
  font-size: 16px;
  color: #333;
}
.section-delete-button:hover {
  color: $color-danger;
}

.section-list-enter-active,
.section-list-leave-active {
  transition: all 0.5s ease;
}
.section-list-enter-from,
.section-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
