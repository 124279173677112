<template>
  <div v-loading="loading">
    <div style="display: flex; align-items: center">
      <label style="word-break: normal">Select WA Number:</label>
      <el-select
        style="margin-left: 5px; width: 500px"
        @change="handleChange"
        v-model="selectedServer"
        placeholder="Select"
        filterable
      >
        <el-option
          v-for="server in servers"
          :key="server.wa_id"
          :label="formatLabel(server.url, server.wa_id)"
          :value="server.url"
        ></el-option>
      </el-select>
      <label style="margin-left: auto; word-break: normal">
        Server Status:
        <el-button :disabled="true" :type="statusIndicator" size="mini" plain>{{
          (server_health || "disconnected").toUpperCase()
        }}</el-button>
      </label>
    </div>

    <el-tabs v-if="selectedServer">
      <el-tab-pane v-if="showAdvanced" label="Registration">
        <RegisterVerifyTab v-if="server_health" :wa_endpoint="selectedServer"></RegisterVerifyTab>
      </el-tab-pane>
      <el-tab-pane label="Message Templates">
        <MessageTemplateTab
          v-if="server_health"
          :messageTemplates="messageTemplates"
          :wa_endpoint="selectedServer"
        ></MessageTemplateTab>
      </el-tab-pane>
      <el-tab-pane label="Bulk Send">
        <BulkSendTab
          v-if="server_health === 'connected' || server_health === 'connecting'"
          :messageTemplates="messageTemplates"
          :wa_endpoint="selectedServer"
        ></BulkSendTab>
      </el-tab-pane>
      <el-tab-pane label="Message Status">
        <MessageStatusTab
          v-if="server_health === 'connected' || (server_health === 'connecting' && selected_waid)"
          :wa_id="selected_waid"
          :wa_endpoint="selectedServer"
        ></MessageStatusTab>
      </el-tab-pane>
      <el-tab-pane label="Analytics">
        <AnalyticsTab
          v-if="server_health === 'connected' || (server_health === 'connecting' && selected_waid)"
          :wa_id="selected_waid"
          :wa_endpoint="selectedServer"
        ></AnalyticsTab>
      </el-tab-pane>

      <!-- <el-tab-pane label="Send Messages" name="sm">
        <SendMessagesTab
          v-if="server_health === 'connected' || server_health === 'connecting'"
          :wa_endpoint="selectedServer"
        ></SendMessagesTab>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="Groups">
        <GroupsTab
          v-if="server_health === 'connected' || server_health === 'connecting'"
          :groups="groups"
          :wa_endpoint="selectedServer"
        ></GroupsTab>
      </el-tab-pane> -->
      <el-tab-pane v-if="databaseInfo" label="Message Logs">
        <MessageLogsTab
          v-if="server_health === 'connected' || server_health === 'connecting'"
          :wa_endpoint="selectedServer"
        ></MessageLogsTab>
      </el-tab-pane>
      <el-tab-pane label="Settings">
        <ApplicationSettingsTab
          v-if="
            ((server_health === 'connected' || server_health === 'connecting') &&
              Object.keys(application_settings).length > 0) ||
            cloudAPISettings?.enabled
          "
          :profile_settings="profile_settings"
          :application_settings="application_settings"
          :groups="groups"
          :wa_endpoint="selectedServer"
          :cloudAPISettings="cloudAPISettings"
        ></ApplicationSettingsTab>
      </el-tab-pane>
      <!-- <el-tab-pane label="Profile Settings">
        <ProfileSettingsTab
          v-if="
            (server_health === 'connected' || server_health === 'connecting') &&
            Object.keys(application_settings).length > 0 &&
            Object.keys(profile_settings).length > 0
          "
          :profile_settings="profile_settings"
          :application_settings="application_settings"
          :wa_endpoint="selectedServer"
        ></ProfileSettingsTab>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import RegisterVerifyTab from "@/components/WhatsApp/RegisterVerifyTab";
import MessageTemplateTab from "@/components/WhatsApp/MessageTemplateTab";
import BulkSendTab from "@/components/WhatsApp/BulkSendTab";
import ApplicationSettingsTab from "@/components/WhatsApp/ApplicationSettingsTab";
// import ProfileSettingsTab from "@/components/WhatsApp/ProfileSettingsTab";
// import SendMessagesTab from "@/components/WhatsApp/SendMessagesTab";
// import GroupsTab from "@/components/WhatsApp/GroupsTab";
import AnalyticsTab from "@/components/WhatsApp/AnalyticsTab";
import MessageStatusTab from "@/components/WhatsApp/MessageStatusTab";
import MessageLogsTab from "@/components/WhatsApp/MessageLogsTab";

import Vue from "vue";

export default Vue.extend({
  components: {
    RegisterVerifyTab,
    MessageTemplateTab,
    BulkSendTab,
    // SendMessagesTab,
    ApplicationSettingsTab,
    // ProfileSettingsTab,
    // GroupsTab,
    AnalyticsTab,
    MessageLogsTab,
    MessageStatusTab,
  },
  data() {
    return {
      server_health: "",
      servers: [],
      selectedServer: "",
      loading: false,
      analytics: {},
    };
  },
  computed: {
    ...mapGetters(["showAdvanced"]),
    messageTemplates() {
      return this.$store.state.whatsapp.templates || [];
    },
    application_settings() {
      return this.$store.state.whatsapp.application_settings || {};
    },
    profile_settings() {
      return this.$store.state.whatsapp.profile_settings || {};
    },
    groups() {
      return _.get(this, `$store.state.whatsapp.groups['${this.selectedServer}']`, []);
    },
    databaseInfo() {
      return (
        this.$store.state.modules.whatsapp.databaseHostname &&
        this.$store.state.modules.whatsapp.databasePort &&
        this.$store.state.modules.whatsapp.databaseType &&
        this.$store.state.modules.whatsapp.databaseUsername &&
        this.$store.state.modules.whatsapp.databasePassword &&
        this.$store.state.modules.whatsapp.databasePrefix
      );
    },
    statusIndicator() {
      if (this.server_health === "connected") {
        return "success";
      } else if (this.server_health === "connecting") {
        return "warning";
      } else if (this.server_health === "uninitialized") {
        return "danger";
      } else if (this.server_health === "unregistered") {
        return "info";
      }
      return "danger";
    },
    selected_waid() {
      const selectedServer = _.find(this.servers, (server) => server.url === this.selectedServer);
      if (selectedServer && selectedServer.wa_id) {
        return selectedServer.wa_id;
      }
      return "";
    },
    cloudAPISettings() {
      return this.$store.state.modules.whatsapp.cloudAPIConfig;
    },
  },
  methods: {
    formatLabel(url, wa_id) {
      return wa_id ? `${wa_id} (${url})` : url;
    },
    handleChange(val) {
      this.loading = true;
      this.$store
        .dispatch("CHECK_WHATSAPP_SERVER_STATUS", {
          data: { wa_endpoint: val },
        })
        .then((data) => {
          this.loading = false;
          this.$data.server_health = data;
          if (data === "connected" || data === "connecting") {
            if (!this.cloudAPISettings?.enabled) {
              this.$store.dispatch("GET_WHATSAPP_APPLICATION_SETTINGS", {
                data: { wa_endpoint: val },
              });
            }
            this.$store.dispatch("GET_WHATSAPP_PROFILE_SETTINGS", {
              data: { wa_endpoint: val },
            });
          }
          this.$store.dispatch("GET_WHATSAPP_MESSAGE_TEMPLATES", {
            data: { wa_endpoint: val },
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$message({
            type: "error",
            message: `An error occurred while trying fetch server status or WhatsApp data!`,
          });
        });
    },
  },

  mounted() {
    this.$store.dispatch("GET_WHATSAPP_SERVERS").then((data) => {
      this.$data.servers = data;
    });
  },
});
</script>
