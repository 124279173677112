<template>
  <div style="max-height: 400px; overflow-y: auto">
    <el-form ref="userDetails2" :model="value" label-width="120px">
      <el-form-item label="Name">
        <el-input v-model="value.name"></el-input>
      </el-form-item>
      <el-form-item label="Alias">
        <el-input v-model="value.alias"></el-input>
      </el-form-item>
      <el-form-item label="Mobile No">
        <el-input v-model="value.mobileNo"></el-input>
      </el-form-item>
      <el-form-item label="Email">
        <el-input v-model="value.email"></el-input>
      </el-form-item>
      <el-form-item label="Comments">
        <el-input
          type="textarea"
          v-model="value.comments"
          show-word-limit
          maxlength="2048"
          rows="6"
        ></el-input>
      </el-form-item>
      <div v-if="updated">
        <div v-for="(setting, key) in customFields" :key="key">
          <el-form-item :label="setting.label || key">
            <component
              v-model="customValues"
              :name="key"
              :setting="setting"
              :is="toComponent(setting)"
              :mode="smartFieldMode"
            ></component>
          </el-form-item>
        </div>
      </div>
      <el-form-item>
        <el-button type="primary" @click="saveUserDetails">Save</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SmartFieldString from "@/components/ModuleEditor/SmartFields/String";
import SmartFieldSelect from "@/components/ModuleEditor/SmartFields/Select";
import SmartFieldMultiselect from "@/components/ModuleEditor/SmartFields/Multiselect";
import SmartFieldTextarea from "@/components/ModuleEditor/SmartFields/Textarea";

export default {
  name: "UserDetails",
  props: ["selectedChat", "value"],
  components: {
    SmartFieldString,
    SmartFieldSelect,
    SmartFieldMultiselect,
    SmartFieldTextarea,
  },
  data() {
    return {
      customValues: {},
      updated: false,
      smartFieldMode: "form",
    };
  },
  watch: {
    value(newVal, oldVal) {
      this.updated = false;
      const customUserDetailsFields = _.get(
        this,
        "$store.state.modules.handover.customUserProfile.fields",
        {}
      );
      const extended = Object.keys(customUserDetailsFields).reduce((acc, curr) => {
        return !newVal[curr]
          ? { ...acc, [curr]: customUserDetailsFields[curr]?.default || "" }
          : { ...acc, [curr]: newVal[curr] };
      }, {});
      this.customValues = extended;
      this.$nextTick(() => {
        this.updated = true;
      });
    },
  },
  computed: {
    customFields() {
      const customUserDetailsEnabled = _.get(
        this,
        "$store.state.modules.handover.customUserProfile.enabled",
        false
      );
      if (!customUserDetailsEnabled) {
        return {};
      }
      const customUserDetailsFields = _.get(
        this,
        "$store.state.modules.handover.customUserProfile.fields",
        {}
      );
      return customUserDetailsFields;
    },
  },
  methods: {
    toComponent(setting) {
      const type = _.capitalize(setting.type);
      const componentName = `SmartField${type}`;
      const componentExist = this.$options.components[componentName];
      return componentExist ? componentName : "SmartFieldString";
    },
    saveUserDetails() {
      this.$store
        .dispatch("UPDATE_USER_DETAILS", {
          chat: this.selectedChat,
          userDetails: { ...this.value, ...this.customValues },
        })
        .then((isSuccessful) => {
          if (isSuccessful) {
            this.$message({
              type: "success",
              message: "Successfully saved user details",
            });
          } else {
            this.$message({
              type: "error",
              message: "Failed to save user details",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "An unexpected error occurred while saving user details",
          });
        });
    },
  },
};
</script>
