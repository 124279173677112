<template>
  <el-container>
    <el-main>
      <el-tabs style="margin-bottom: 20px" type="border-card">
        <el-row type="flex" justify="end">
          <el-button @click="refreshFetch" icon="el-icon-refresh">Refresh</el-button>
        </el-row>
        <el-tab-pane label="Pending">
          <el-table
            ref="pendingTable"
            :data="pendingBulkMessage"
            @row-click="viewBulkMessage"
            highlight-current-row
            :row-class-name="'pointer-row'"
            style="width: 100%"
            height="300"
          >
            <el-table-column prop="req_time" label="Created Time"></el-table-column>
            <el-table-column label="Send Time">
              <template slot-scope="scope">
                <span>
                  {{
                    scope.row.send_time === "-"
                      ? "Send now"
                      : "Scheduled for: " + scope.row.send_time
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="template_id" label="Template Name"></el-table-column>
            <el-table-column v-if="makerCheckerEnable" label="Maker">
              <template slot-scope="scope">
                <span>{{ scope.row.maker_email || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Status">
              <template slot-scope="scope">
                <span>{{ sanitizeStatus(scope.row.status) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Action">
              <template slot-scope="scope">
                <el-button
                  plain
                  style="margin-left: 10px; margin-bottom: 5px"
                  @click="approveBulkMessage(scope.row)"
                  type="success"
                  size="mini"
                  v-if="makerCheckerAction && scope.row.status === 'pending'"
                  :disabled="formattedSampleMessageTemplateText && viewMode.id !== scope.row._id"
                >
                  Approve
                </el-button>
                <el-button
                  plain
                  style="margin-bottom: 5px"
                  @click="rejectBulkMessage(scope.row)"
                  type="danger"
                  size="mini"
                  v-if="makerCheckerAction && scope.row.status === 'pending'"
                  :disabled="formattedSampleMessageTemplateText && viewMode.id !== scope.row._id"
                >
                  Reject
                </el-button>
                <el-button
                  plain
                  style="margin-bottom: 5px"
                  @click="cancelBulkMessage(scope.row._id)"
                  type="warning"
                  size="mini"
                  :disabled="formattedSampleMessageTemplateText && viewMode.id !== scope.row._id"
                >
                  {{ scope.row.status === "rejected" ? "Delete" : "Cancel" }}
                </el-button>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Select message by clicking the row for action"
                  placement="top"
                >
                  <i style="margin-left: 5px" class="el-icon-question has-text-grey" />
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane v-if="makerCheckerEnable" label="Checked">
          <el-table
            ref="checkedTable"
            :data="checkedBulkMessage"
            highlight-current-row
            @row-click="viewBulkMessage"
            :row-class-name="'pointer-row'"
            style="width: 100%"
            height="300"
          >
            <el-table-column prop="req_time" label="Created Time"></el-table-column>
            <el-table-column label="Send Time">
              <template slot-scope="scope">
                <span>
                  {{
                    scope.row.send_time === "-"
                      ? "Send now"
                      : "Scheduled for: " + scope.row.send_time
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="template_id" label="Template Name"></el-table-column>
            <el-table-column v-if="makerCheckerEnable" label="Maker">
              <template slot-scope="scope">
                <span>{{ scope.row.maker_email || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Status">
              <template slot-scope="scope">
                <span>{{ sanitizeStatus(scope.row.status) }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="makerCheckerEnable" label="Checker">
              <template slot-scope="scope">
                <span>{{ scope.row.checker_email || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="makerCheckerEnable" label="Notes">
              <template slot-scope="scope">
                <span>{{ scope.row.checker_note || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="checker_time" label="Check Time"></el-table-column>
            <el-table-column label="Action">
              <template slot-scope="scope">
                <el-button
                  plain
                  style="margin-left: 10px; margin-bottom: 5px"
                  @click="cancelBulkMessage(scope.row._id)"
                  type="warning"
                  size="mini"
                  :disabled="formattedSampleMessageTemplateText && viewMode.id !== scope.row._id"
                >
                  {{ scope.row.status === "rejected" ? "Delete" : "Cancel" }}
                </el-button>

                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Click the row for action"
                  placement="left"
                >
                  <i style="margin-left: 5px" class="el-icon-question has-text-grey" />
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane v-if="makerCheckerEnable" label="Sent">
          <el-date-picker
            v-model="datePicked"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            format="dd/MM/yyyy"
            :default-time="['00:00:00', '23:59:59']"
            style="margin-bottom: 10px"
          />
          <el-table
            ref="sentTable"
            :data="sentBulkMessage"
            highlight-current-row
            @row-click="viewBulkMessage"
            :row-class-name="'pointer-row'"
            style="width: 100%"
            height="300"
          >
            <el-table-column prop="req_time" label="Created Time"></el-table-column>
            <el-table-column label="Send Time">
              <template slot-scope="scope">
                <span>
                  {{
                    scope.row.send_time === "-"
                      ? "Send now"
                      : "Scheduled for: " + scope.row.send_time
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="template_id" label="Template Name"></el-table-column>
            <el-table-column v-if="makerCheckerEnable" label="Maker">
              <template slot-scope="scope">
                <span>{{ scope.row.maker_email || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Status">
              <template slot-scope="scope">
                <span>{{ sanitizeStatus(scope.row.status) }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="makerCheckerEnable" label="Checker">
              <template slot-scope="scope">
                <span>{{ scope.row.checker_email || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="checker_time" label="Check Time"></el-table-column>
          </el-table>
          <el-pagination
            background
            small
            layout="prev, pager, next"
            :pageSize="sentTablePageSize"
            :current-page.sync="sentTableCurrentPage"
            :total="sentTableTotalData"
            @prev-click="(page) => (sentTableCurrentPage = page)"
            @next-click="(page) => (sentTableCurrentPage = page)"
            @current-change="(page) => (sentTableCurrentPage = page)"
            style="margin-top: 15px; display: inline-block"
          ></el-pagination>
        </el-tab-pane>
      </el-tabs>
      <el-card>
        <el-select
          v-model="selectedSampleMessageTemplate"
          placeholder="Select a message template"
          filterable
          @change="handleSampleMessageTemplateChange"
          :disabled="viewMode.enable"
        >
          <el-option
            v-for="item in uniqueMessageTemplates"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          />
        </el-select>
        <el-select
          style="margin-left: 5px"
          v-model="selectedSampleMessageTemplateLanguage"
          placeholder="Select a language"
          filterable
          @change="handleSampleMessageTemplateLanguageChange"
          :disabled="viewMode.enable"
        >
          <el-option
            v-for="language in availableSampleMessageTemplateLanguages"
            :key="language"
            :label="language"
            :value="language"
          />
        </el-select>
        <el-button
          plain
          type="info"
          style="margin-left: 5px"
          @click="exitViewBulkMessage"
          :disabled="!viewMode.enable"
        >
          Exit View Mode
        </el-button>
        <el-divider content-position="center">Preview</el-divider>
        <el-row style="display: flex; flex-direction: column; align-items: center">
          <WhatsAppMessageTemplatePreview
            :header="previewMessageTemplateHeader"
            :body="previewMessageTemplateBody"
            :footer="previewMessageTemplateFooter"
            :buttons="previewMessageTemplateButtons"
            :button-type="previewButtonType"
          />
        </el-row>
        <el-upload
          v-if="
            !viewMode.enable &&
            selectedSampleMessageTemplateLanguage &&
            selectedSampleMessageTemplate
          "
          drag
          class="upload-demo"
          action="#"
          :before-upload="beforeUpload"
          :on-remove="handleRemove"
          :file-list="fileList"
        >
          <i class="el-icon-upload upload-icon" />
          <div class="el-upload__text">Drag file / click to upload</div>
        </el-upload>
        <el-row v-if="selectedSampleMessageTemplateLanguage && selectedSampleMessageTemplate">
          <el-button
            v-if="!viewMode.enable"
            type="danger"
            @click="handleAdd"
            :disabled="viewMode.enable"
          >
            Add New Row
          </el-button>
          <el-button v-if="!viewMode.enable" type="danger" @click="handleDownloadTemplate">
            Download Template
          </el-button>
          <el-upload
            style="display: inline-block; position: relative; left: 10px"
            class="upload-demo hidden-items"
            action="#"
            :before-upload="beforeHeaderMediaUpload()"
            :accept="acceptedMessageTemplateHeaderMediaTypes"
          >
            <el-button
              type="danger"
              v-if="nonTextHeaderTemplate"
              :disabled="sendList.length === 0 || viewMode.enable"
            >
              Bulk Upload Media
            </el-button>
          </el-upload>
        </el-row>

        <div v-if="sendList && sendList.length > 0 && messageTemplateBodyText !== ''">
          <el-table :data="formattedSendList" style="width: 100%">
            <el-table-column
              v-for="(value, index) in tableHeaders"
              :prop="value.prop"
              :label="value.label"
              :key="index"
              min-width="150px"
            >
              <template slot-scope="scope">
                <div v-if="index < tableHeaders.length">
                  <el-upload
                    v-if="value.prop === 'hm'"
                    :disabled="viewMode.enable"
                    style="display: inline-block; position: relative; left: 10px"
                    class="upload-demo hidden-items"
                    action="#"
                    :before-upload="beforeHeaderMediaUpload(scope.row, scope.$index)"
                    :accept="acceptedMessageTemplateHeaderMediaTypes"
                  >
                    <el-button
                      type="danger"
                      v-if="!scope.row[value.prop]"
                      :disabled="viewMode.enable"
                      :loading="isUpload"
                    >
                      Upload
                    </el-button>
                    <div v-else-if="messageTemplateHeaderType === 'IMAGE'">
                      <el-button type="danger" @click.stop="getMediaPreview(scope.row)">
                        <i class="el-icon-picture" style="font-size: 12px" />Open</el-button
                      >
                    </div>
                    <div v-else-if="messageTemplateHeaderType === 'VIDEO'">
                      <el-button type="danger" @click.stop="getMediaPreview(scope.row)">
                        <i class="el-icon-video-camera" style="font-size: 12px" />Open</el-button
                      >
                    </div>
                    <div v-else-if="messageTemplateHeaderType === 'DOCUMENT'">
                      <el-button type="danger" @click.stop="getMediaPreview(scope.row)">
                        <i class="el-icon-document" style="font-size: 12px" />Open</el-button
                      >
                    </div>
                  </el-upload>
                  <el-input
                    v-else
                    v-model="scope.row[value.prop]"
                    :disabled="viewMode.enable"
                  ></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Operations" min-width="150px">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  :disabled="viewMode.enable"
                >
                  Delete
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            layout="total, sizes, prev, pager, next"
            :page-size="currentTablePageSize"
            :current-page.sync="currentTablePage"
            :total="sendList.length"
          ></el-pagination>
          <el-row>
            <el-checkbox
              :disabled="viewMode.enable"
              v-model="isStoreInteraction.text"
              border
              style="margin: 10px 0"
            >
              Store text interaction
            </el-checkbox>
            <el-tooltip
              class="item"
              effect="dark"
              content="if checked, it will store this text message into chat session and interaction"
              placement="top"
            >
              <i
                style="margin-left: 5px; margin-right: 20px"
                class="el-icon-question has-text-grey"
              />
            </el-tooltip>
            <el-checkbox
              v-if="nonTextHeaderTemplate"
              :disabled="viewMode.enable"
              v-model="isStoreInteraction.media"
              border
              style="margin: 10px 0"
            >
              Store media interaction
            </el-checkbox>
            <el-tooltip
              v-if="nonTextHeaderTemplate"
              class="item"
              effect="dark"
              content="if checked, it will store this media message into chat session and interaction"
              placement="top"
            >
              <i style="margin-left: 5px" class="el-icon-question has-text-grey" />
            </el-tooltip>
          </el-row>
          <el-checkbox
            :disabled="viewMode.enable"
            v-model="forceSend"
            border
            style="margin: 10px 0"
          >
            Force send
          </el-checkbox>
          <el-tooltip
            class="item"
            effect="dark"
            content="if checked, will send message to all number list even the number is unsubscribe"
            placement="top"
          >
            <i style="margin-left: 5px" class="el-icon-question has-text-grey" />
          </el-tooltip>
          <div>
            <el-radio
              v-model="sendType"
              label="now"
              v-if="!viewMode.enable"
              :disabled="viewMode.enable"
              border
            >
              Send Now
            </el-radio>
            <el-radio
              v-model="sendType"
              label="later"
              v-if="!viewMode.enable"
              :disabled="viewMode.enable"
              border
            >
              Send Later
            </el-radio>
          </div>
          <br />
          <div v-if="sendType === 'later' && !viewMode.enable">
            <el-date-picker
              time-arrow-control
              v-model="sendTime"
              type="datetime"
              placeholder="Select send date and time"
              :disabled="viewMode.enable"
            ></el-date-picker>
            <el-select
              v-model="selectedTimezone"
              style="margin-left: 5px"
              :disabled="viewMode.enable || !sendTime"
            >
              <el-option
                v-for="item in timeZoneList"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <br />
          <el-button type="primary" @click="submitSend" :disabled="viewMode.enable">
            Submit
          </el-button>
        </div>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import * as Papa from "papaparse";
import _ from "lodash";
import XLSX from "xlsx";
import * as moment from "moment";
import { mapGetters } from "vuex";
import { timezoneList } from "./timezoneList";
import WhatsAppMessageTemplatePreview from "./WhatsAppMessageTemplatePreview";
import * as Sentry from "@sentry/browser";
import { getDefaultPlaceholderImage } from "@/helperMethods/util";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";

export default {
  name: "BulkSendTab",
  components: {
    WhatsAppMessageTemplatePreview,
  },
  props: {
    messageTemplates: Array,
    wa_endpoint: String,
  },
  data() {
    return {
      currentTablePage: 1,
      currentTablePageSize: 10,
      tableHeaders: [],
      fileList: [],
      selectedSampleMessageTemplateLanguage: "",
      selectedSampleMessageTemplate: "",
      selectedMessageTemplate: {},
      sendList: [],
      uploadedMessageTemplateMedia: {},
      visualizedIndex: 0,
      sendType: "now",
      sendTime: null,
      sendTimeFinal: null,
      scheduledBulkMessage: [],
      pendingBulkMessage: [],
      checkedBulkMessage: [],
      sentBulkMessage: [],
      viewMode: { enable: false, id: "" },
      selectedTimezone: "",
      datePicked: [undefined, undefined],
      forceSend: false,
      isStoreInteraction: {
        text: true,
        media: false,
      },
      ignoredNumbers: [],
      // - sent table pagination
      sentTableTotalData: 0,
      sentTablePageSize: 10,
      sentTableCurrentPage: 1,
      isUpload: false,
    };
  },
  computed: {
    ...mapGetters(["getUserAuthentication"]),
    cloudAPISettings() {
      return this.$store.state.modules.whatsapp.cloudAPIConfig;
    },
    nonTextHeaderTemplate() {
      return this.messageTemplateHeaderType && this.messageTemplateHeaderType !== "TEXT";
    },

    timeZoneList() {
      return timezoneList;
    },

    noOfIgnored() {
      return this.forceSend
        ? 0
        : _.chain(this.sendList)
            .map("phone_number")
            .intersection(this.ignoredNumbers)
            .get("length")
            .value();
    },

    noOfMessageToSendAll() {
      return _.sumBy(this.sendList, (o) => (o.send && o.phone_number ? 1 : 0));
    },

    noOfMessageToSendNotIgnored() {
      return this.noOfMessageToSendAll - this.noOfIgnored;
    },
    /* Start Header Properties */

    previewMessageTemplateHeader() {
      return {
        format: this.messageTemplateHeaderType,
        text: this.formattedMessageTemplateHeaderText,
      };
    },
    sampleMessageTemplateHeaderText() {
      if (this.messageTemplateHeaderType !== "TEXT") return "";
      return _.get(this.messageTemplateHeader, "text", "");
    },
    messageTemplateHeaderType() {
      if (!this.messageTemplateHeader) return "";
      return this.messageTemplateHeader.format;
    },
    messageTemplateHeader() {
      const { components } = this.selectedMessageTemplate;
      return _.find(components, (component) => component.type === "HEADER");
    },
    formattedMessageTemplateHeaderText() {
      if (this.messageTemplateHeaderType !== "TEXT") {
        return `[${this.messageTemplateHeaderType}]`;
      }
      let formatted = this.sampleMessageTemplateHeaderText;
      if (!formatted) return "";
      if (this.sendList.length > 0) {
        const headerParameters = _.filter(this.tableHeaders, (header) =>
          header.prop.startsWith("hp")
        );
        for (let i = 0; i < headerParameters.length; i++) {
          const header = headerParameters[i];
          const param = _.get(this.currentlyVisualizedRow, header.prop);
          if (param) {
            formatted = formatted.replace(`{{${i + 1}}}`, param);
          }
        }
      }
      return formatted;
    },

    /* End Header Properties */

    /* Start Body Properties */
    previewMessageTemplateBody() {
      return {
        text: this.formattedSampleMessageTemplateText || "No Preview Available",
      };
    },

    messageTemplateBodyText() {
      const components = _.get(this.selectedMessageTemplate, "components", {});
      const body = _.find(components, (component) => component.type === "BODY");
      const message = _.get(body, "text", "");
      return message;
    },

    formattedSampleMessageTemplateText() {
      let formatted = this.messageTemplateBodyText;
      if (this.sendList.length > 0) {
        const bodyParameters = _.filter(
          this.tableHeaders,
          (header) => header.prop.startsWith("p") && header.prop !== "phone_number"
        );
        for (let i = 0; i < bodyParameters.length; i++) {
          const header = bodyParameters[i];
          const param = _.get(this.currentlyVisualizedRow, header.prop);
          if (param) {
            formatted = formatted.replace(`{{${i + 1}}}`, param);
          }
        }
      }
      return formatted;
    },
    /* End Body Properties */

    /* Start Footer Properties */
    previewMessageTemplateFooter() {
      return { text: this.messageTemplateFooterMessage };
    },

    messageTemplateFooter() {
      const { components } = this.selectedMessageTemplate;
      return _.find(components, (component) => component.type === "FOOTER");
    },
    messageTemplateFooterMessage() {
      return _.get(this.messageTemplateFooter, "text", "");
    },
    /* End Footer Properties */

    /* Start Button Properties */
    previewButtonType() {
      if (!this.messageTemplateButtons) return undefined;

      const quickReplyButtons = _.find(
        this.messageTemplateButtons.buttons,
        (button) => button.type === "QUICK_REPLY"
      );
      if (quickReplyButtons) {
        return "QUICK_REPLY";
      }
      const callToActionButtons = _.find(
        this.messageTemplateButtons.buttons,
        (button) => button.type === "PHONE_NUMBER" || button.type === "URL"
      );
      if (callToActionButtons) {
        return "CALL_TO_ACTION";
      }

      return undefined;
    },
    messageTemplateButtons() {
      const { components } = this.selectedMessageTemplate;
      return _.find(components, (component) => component.type === "BUTTONS") || [];
    },
    previewMessageTemplateButtons() {
      if (!this.messageTemplateButtons) {
        return [];
      }

      const footerButtonParameters = _.filter(this.tableHeaders, (header) =>
        header.prop.startsWith("bp")
      );

      return _.map(this.messageTemplateButtons.buttons, (button, buttonIndex) => {
        if (button.type === "URL") {
          let formattedUrl = button.url;
          const footerParameterMatches = formattedUrl.match(/({{\d+}})/g) || [];
          formattedUrl = _.reduce(
            footerParameterMatches,
            (accum, match, paramIndex) => {
              const replacement = _.get(
                this.currentlyVisualizedRow,
                `bp${buttonIndex + 1}${paramIndex + 1}`,
                match
              );
              return accum.replace(match, replacement);
            },
            formattedUrl
          );

          return {
            ...button,
            url: formattedUrl,
          };
        }
        return button;
      });
    },
    /* End Button Properties */

    acceptedMessageTemplateHeaderMediaTypes() {
      if (this.messageTemplateHeaderType === "IMAGE") {
        return "image/jpeg,image/png";
      } else if (this.messageTemplateHeaderType === "VIDEO") {
        return "video/mp4,video/3gpp";
      } else if (this.messageTemplateHeaderType === "DOCUMENT") {
        return "text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/pdf";
      } else {
        return "";
      }
    },

    currentlyVisualizedRow() {
      const visualized = this.sendList[this.visualizedIndex];
      return visualized;
    },

    approvedMessageTemplates() {
      return _.filter(this.messageTemplates, (o) => o.status === "APPROVED");
    },
    uniqueMessageTemplates() {
      return _.sortedUniqBy(this.approvedMessageTemplates, (o) => o.name) || [];
    },
    availableSampleMessageTemplateLanguages() {
      return (
        _.map(
          _.filter(
            this.approvedMessageTemplates,
            (o) => o.name === this.selectedSampleMessageTemplate
          ),
          (o) => o.language
        ) || []
      );
    },

    formattedSendList() {
      const formattedArr = [];
      const start = (this.currentTablePage - 1) * this.currentTablePageSize;
      const end =
        this.currentTablePage * this.currentTablePageSize > this.sendList.length
          ? this.sendList.length
          : this.currentTablePage * this.currentTablePageSize;
      for (let i = start; i < end; i++) {
        formattedArr.push(this.sendList[i]);
      }
      return formattedArr;
    },
    makerCheckerEnable() {
      return this.$store.state.modules.whatsapp.makerChecker;
    },
    isAuthorizedToExecute() {
      const isAuthorized = checkRoles(getPermittedRoles("whatsapp_checker"));

      return isAuthorized;
    },
    makerCheckerAction() {
      return this.makerCheckerEnable && this.isAuthorizedToExecute;
    },
  },
  watch: {
    messageTemplates(val) {
      this.selectedSampleMessageTemplateLanguage = "";
      this.selectedSampleMessageTemplate = "";
    },
    datePicked() {
      this.fetchSent();
    },
    sentTableCurrentPage() {
      this.fetchSent();
    },
  },
  mounted() {
    this.fetchScheduledTask();
    this.fetchIgnoredNumbers();
    // get timezone
    const utc = -(new Date().getTimezoneOffset() / 60);
    const selected = this.timeZoneList.find((o) => o.value === String(utc));
    this.selectedTimezone = selected.value;
  },
  methods: {
    refreshFetch() {
      this.fetchScheduledTask();
      this.fetchSent();
    },
    fetchScheduledTask() {
      this.$store
        .dispatch("WHATSAPP_GET_SCHEDULE_BULK", {
          data: {
            wa_endpoint: this.wa_endpoint,
          },
        })
        .then(({ messages }) => {
          if (messages && messages.length) {
            const minTimestampToRefetch = _.minBy(messages, (message) => {
              return message.send_timestamp;
            });
            if (minTimestampToRefetch) {
              const now = Date.now();
              const delay = minTimestampToRefetch.send_timestamp - now;
              if (delay > 0) {
                setTimeout(() => {
                  this.fetchScheduledTask();
                }, delay);
              }
            }
          }
          this.pendingBulkMessage = [];
          this.checkedBulkMessage = [];
          for (const message of messages) {
            this.mutateDataTable(message);
            if (
              this.makerCheckerEnable &&
              (message.status === "waiting" || message.status === "rejected")
            ) {
              this.checkedBulkMessage.push(message);
            } else {
              this.pendingBulkMessage.push(message);
            }
          }
        })
        .catch((err) => {
          this.pendingBulkMessage = [];
          this.checkedBulkMessage = [];
        });
      this.exitViewBulkMessage();
    },
    fetchSent() {
      const [startDate, endDate] = this.datePicked;
      if (startDate && endDate && this.makerCheckerEnable) {
        const { start, end } = this.syncTimezone({
          startDate,
          endDate,
        });
        this.$store
          .dispatch("WHATSAPP_GET_SENT_SCHEDULE_BULK", {
            data: {
              wa_endpoint: this.wa_endpoint,
              start,
              end,
              limit: this.sentTablePageSize,
              offset: this.sentTableCurrentPage - 1,
            },
          })
          .then(({ messages, count }) => {
            this.sentTableTotalData = count;
            this.sentBulkMessage = [];
            for (const message of messages) {
              this.mutateDataTable(message);
              this.sentBulkMessage.push(message);
            }
          })
          .catch((err) => {
            this.sentBulkMessage = [];
          });
      }
    },
    fetchIgnoredNumbers() {
      this.$store
        .dispatch("WHATSAPP_GET_IGNORED_NUMBERS", {
          data: {
            wa_server: this.wa_endpoint,
          },
        })
        .then(({ numbers, count }) => {
          this.ignoredNumbers = numbers.map((o) => o.wa_number);
        })
        .catch((err) => {
          this.ignoredNumbers = [];
        });
    },
    handleDownloadTemplate() {
      const headersToBeExported = _.chain(this.tableHeaders)
        .filter((header) => header.prop !== "hm")
        .value();
      const headerLabels = _.map(headersToBeExported, (headerObj) => headerObj.label);
      const sendData = _.map(this.sendList, (sendObj) => {
        return _.map(headersToBeExported, (headerObj) => {
          const dataValue = sendObj[headerObj.prop];
          if (dataValue === undefined) return "";
          return dataValue;
        });
      });
      const toExport = [headerLabels, ...sendData];
      const fileName = `${this.selectedSampleMessageTemplate}_${this.selectedSampleMessageTemplateLanguage}_template`;
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(toExport);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet");
      XLSX.writeFile(wb, `${fileName}.csv`, {});
    },
    getMessageTemplateHeaderImage(row) {
      const imageInBase64 = this.uploadedMessageTemplateMedia[row.hm];
      if (!imageInBase64) {
        return getDefaultPlaceholderImage();
      }
      return imageInBase64;
    },
    arrayBufferToBase64(bytes) {
      const arr = Object.keys(bytes).map((key) => bytes[key]);
      const bin = arr.reduce((acc, curr) => `${acc}${String.fromCharCode(curr)}`, "");
      return btoa(bin);
    },
    getMediaPreview(row) {
      const mediaId = this.uploadedMessageTemplateMedia[row.hm];
      const dispatchFunction = this.cloudAPISettings?.enabled
        ? "WHATSAPP_CLOUD_GET_MEDIA_BY_ID"
        : "WHATSAPP_GET_MEDIA_BY_ID";
      this.$store
        .dispatch(dispatchFunction, {
          data: {
            endpoint: this.wa_endpoint,
            id: mediaId,
          },
        })
        .then((res) => {
          const base64 = `data:${res.contentType};base64,${this.arrayBufferToBase64(res.data)}`;
          let a = document.createElement("a");
          a.target = "_blank";
          a.href = base64;
          a.download = row.hm;
          a.click();
        });
    },
    handleDelete(index, row) {
      if (index === this.visualizedIndex) {
        this.visualizedIndex = 0;
      }
      this.sendList.splice((this.currentTablePage - 1) * this.currentTablePageSize + index, 1);
    },
    handleVisualize(index, row) {
      this.visualizedIndex = index;
    },
    handleAdd() {
      this.sendList.push({ send: true });
    },
    beforeHeaderMediaUpload(row, index) {
      const self = this;
      return async (file) => {
        this.isUpload = true;
        const base64Data = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

        if (row) {
          if (this.cloudAPISettings?.enabled) {
            this.$store
              .dispatch("WHATSAPP_CLOUD_UPLOAD_MEDIA", file)
              .then((data) => {
                if (data) {
                  this.uploadedMessageTemplateMedia[file.name] = data.id;
                  this.$set(this.sendList, index, { ...row, hm: file.name });
                  this.isUpload = false;
                }
              })
              .catch((error) => {
                this.isUpload = false;
                console.log(error);
              });
          } else {
            this.uploadedMessageTemplateMedia[file.name] = base64Data;
            this.$set(this.sendList, index, { ...row, hm: file.name });
            this.isUpload = false;
          }
        } else {
          this.$confirm(
            `This will replace any existing media configured for each message template, Continue?`,
            {
              confirmButtonText: "Yes",
              cancelButtonText: "No",
              type: "warning",
            }
          )
            .then(() => {
              if (this.cloudAPISettings?.enabled) {
                this.$store
                  .dispatch("WHATSAPP_CLOUD_UPLOAD_MEDIA", file)
                  .then((data) => {
                    if (data) {
                      this.uploadedMessageTemplateMedia = { [file.name]: data.id };
                      this.sendList = this.sendList.map((obj) => {
                        return {
                          ...obj,
                          hm: file.name,
                        };
                      });
                      this.isUpload = false;
                    }
                  })
                  .catch((error) => {
                    this.isUpload = false;
                    console.log(error);
                  });
              } else {
                this.uploadedMessageTemplateMedia = { [file.name]: base64Data };
                this.sendList = this.sendList.map((obj) => {
                  return {
                    ...obj,
                    hm: file.name,
                  };
                });
                this.isUpload = false;
              }
            })
            .catch(() => {
              this.isUpload = false;
            });
        }
        return true;
      };
    },
    beforeUpload(file) {
      const isCSV =
        (file.type === "text/csv" || file.type === "application/vnd.ms-excel") &&
        file.name.endsWith(".csv");
      if (isCSV) {
        this.handleSuccess(file);
      } else {
        this.$message.error("Uploaded file must be in CSV format!");
      }
      return isCSV;
    },
    handleRemove(file, fileList) {
      // console.log("in handleremove");
      // console.log(file);
    },
    populateHeaders(noBodyParams, noHeaderParams, headerType, noOfFooterParametersTuple) {
      this.tableHeaders = [];
      this.tableHeaders.push({
        label: "Phone Number",
        prop: "phone_number",
      });

      if (headerType) {
        if (headerType === "TEXT") {
          for (let i = 1; i <= noHeaderParams; i++) {
            this.tableHeaders.push({
              label: `Header Parameter ${i}`,
              prop: `hp${i}`,
            });
          }
        } else {
          this.tableHeaders.push({
            label: `Header Media`,
            prop: `hm`,
          });
        }
      }

      for (let i = 1; i <= noBodyParams; i++) {
        this.tableHeaders.push({
          label: `Parameter ${i}`,
          prop: `p${i}`,
        });
      }

      for (let i = 1; i <= noOfFooterParametersTuple.length; i++) {
        const noOfParams = noOfFooterParametersTuple[i - 1];

        if (noOfParams === 0) continue;
        for (let k = 1; k <= noOfParams; k++) {
          this.tableHeaders.push({
            label: `Button ${i} Parameter ${k}`,
            prop: `bp${i}${k}`,
          });
        }
      }

      // this.tableHeaders.push({
      //   label: "Send?",
      //   prop: "send",
      // });
    },
    populateRows() {
      const headersToLoop = this.tableHeaders.slice(0, this.tableHeaders.length - 1);

      const updatedList = _.map(this.sendList, (row) => {
        const obj = _.reduce(
          headersToLoop,
          (accum, header) => {
            accum[header.prop] = row[header.prop] || "";
            return accum;
          },
          {}
        );
        if (obj.send === undefined) {
          obj.send = true;
        }

        return obj;
      });
      this.sendList = updatedList;
    },
    handleSuccess(file) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        Papa.parse(e.target.result, {
          header: true,
          transformHeader: (headerValue) => {
            const matchingHeaderObj = _.find(
              this.tableHeaders,
              (headerObj) => headerObj.label === headerValue
            );
            if (!matchingHeaderObj) return "invalid_header";

            return matchingHeaderObj.prop;
          },
          complete: async ({ data }) => {
            if (data.length <= 0) {
              return this.$message({
                type: "info",
                message: `The file uploaded didn't contain any data!`,
              });
            }
            const firstDataRow = _.first(data);
            const containsInvalidHeader = _.has(firstDataRow, "invalid_header");
            if (containsInvalidHeader) {
              return this.$message({
                type: "error",
                message: `The file uploaded contains invalid headers for ${this.selectedSampleMessageTemplate}_${this.selectedSampleMessageTemplateLanguage}!`,
              });
            }
            if (Array.isArray(this.sendList) && this.sendList.length > 0) {
              await this.$confirm(
                `There's already some existing data, would you like to overwrite them?`,
                {
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                  type: "warning",
                }
              )
                .then(() => {
                  this.sendList = [];
                  this.uploadedMessageTemplateMedia = {};
                })
                .catch(() => {});
            }

            const dataWithSendPredicateEvaluated = _.map(data, (dataObj) => {
              return {
                ...dataObj,
                send: true,
              };
            });
            dataWithSendPredicateEvaluated.forEach((dataObj, index) => {
              // Initialize headers
              this.sendList.push(dataObj);
            });

            this.$message({
              type: "success",
              message: `Successfully uploaded ${data.length} rows of data!`,
            });
          },
        });
      };
    },
    handleSizeChange(val) {
      this.currentTablePageSize = val;
    },
    changeMessageTemplate() {
      const messageTemplate = _.find(
        this.approvedMessageTemplates,
        (o) =>
          o.language === this.selectedSampleMessageTemplateLanguage &&
          o.name === this.selectedSampleMessageTemplate
      );
      if (messageTemplate) {
        const components = _.get(messageTemplate, "components", {});
        this.selectedMessageTemplate = messageTemplate;

        const bodyParameterMatches = this.messageTemplateBodyText.match(/({{\d+}})/g);
        const headerParameterMatches = this.sampleMessageTemplateHeaderText.match(/({{\d+}})/g);
        let noOfBodyParameters = 0;
        let noOfHeaderParameters = 0;
        if (Array.isArray(bodyParameterMatches)) {
          noOfBodyParameters = bodyParameterMatches.length;
        }

        if (Array.isArray(headerParameterMatches)) {
          noOfHeaderParameters = headerParameterMatches.length;
        }

        const noOfFooterParametersTuple = _.map(this.messageTemplateButtons.buttons, (button) => {
          if (button.type !== "URL") return 0;

          const footerParameterMatches = button.url.match(/({{\d+}})/g) || [];
          return footerParameterMatches.length;
        });
        this.populateHeaders(
          noOfBodyParameters,
          noOfHeaderParameters,
          this.messageTemplateHeaderType,
          noOfFooterParametersTuple
        );
        this.populateRows();
      }
    },

    handleSampleMessageTemplateChange(val) {
      this.selectedSampleMessageTemplateLanguage = "";
      this.changeMessageTemplate();
    },
    handleSampleMessageTemplateLanguageChange(val) {
      this.sendTime = null;
      this.sendType = "now";
      this.forceSend = false;
      this.changeMessageTemplate();
    },
    sanitizeStatus(val) {
      if (val === "waiting") {
        if (this.makerCheckerEnable) {
          return "Approved";
        }
        return "Waiting for Send";
      }
      return _.startCase(val);
    },
    syncWithSelectedTimezone() {
      const tz = parseInt(this.selectedTimezone);
      const tm = this.sendTimeFinal;
      const sel = tz > -(new Date().getTimezoneOffset() / 60) ? -1 : 1;
      const offset = (tm.getTimezoneOffset() + tz * 60) * 60 * 1000;
      const tempTime = tm.getTime() + offset * sel;
      const finalTime = new Date(tempTime);
      this.sendTimeFinal = finalTime;
    },
    syncTimezone({ startDate, endDate }) {
      const timezone = this.$store.state.modules.whatsapp.timezone
        ? this.$store.state.modules.whatsapp.timezone
        : 8;

      const cloneStart = _.cloneDeep(startDate);
      const cloneEnd = _.cloneDeep(endDate);

      cloneStart.setMinutes(
        -cloneStart.getTimezoneOffset() - cloneStart.getMinutes() - timezone * 60
      );
      cloneEnd.setDate(cloneEnd.getDate() + 1);
      cloneEnd.setHours(0, 0, 0, 0);
      cloneEnd.setMinutes(-cloneEnd.getTimezoneOffset() - cloneEnd.getMinutes() - timezone * 60);

      const start = Math.floor(cloneStart.getTime() / 1000);
      const end = Math.floor(cloneEnd.getTime() / 1000);
      return { start, end };
    },
    submitSend() {
      const invalidNumber = this.sendList.find((item) => !item.phone_number.match(/^\d{8,15}$/));
      if (invalidNumber) {
        this.$message({
          type: "error",
          message: `Invalid phone number format found in the list, ${invalidNumber.phone_number}.`,
          duration: 6000,
        });
        return;
      }
      if (this.makerCheckerEnable) {
        const scheduled = this.sendType === "later" && this.sendTime ? true : false;
        const needApproval = true;
        this.sendBulkLater(scheduled, needApproval);
      } else {
        if (this.sendType === "later" && this.sendTime) {
          this.sendBulkLater();
        } else {
          this.sendBulk();
        }
      }
    },
    sendBulk() {
      const [messageNote, popupNote] = this.ignoredNumbersNote(this.noOfIgnored);
      if (this.noOfMessageToSendAll <= 0 || this.noOfMessageToSendNotIgnored <= 0) {
        return this.$message({
          type: "error",
          message: `Please select at least 1 recipient for the message template!${messageNote}`,
          duration: 6000,
        });
      }

      const seconds = Math.floor(this.noOfMessageToSendAll / 5) + 5;
      this.$confirm(
        `You are about to send ${this.noOfMessageToSendAll} messages${popupNote}, estimated time: ~${seconds} seconds, are you sure?`,
        "Warning",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          const template = _.find(
            this.approvedMessageTemplates,
            (o) =>
              o.name === this.selectedSampleMessageTemplate &&
              o.language === this.selectedSampleMessageTemplateLanguage
          );
          const toSend = _.chain(this.sendList)
            .filter((obj) =>
              obj.send && obj.phone_number && this.forceSend
                ? true
                : !_.includes(this.ignoredNumbers, obj.phone_number)
            )
            .map((obj) => {
              return {
                phone_number: obj.phone_number,
                parameters: _.omit(obj, ["phone_number", "send"]),
              };
            })
            .value();
          const uniqueMediaFilter = _.chain(this.sendList)
            .filter((o) => o.send)
            .map((obj) => obj.hm)
            .compact()
            .uniq()
            .value();

          const uniqueMediaDataToSend = _.reduce(
            this.uploadedMessageTemplateMedia,
            (accum, base64Data, mediaName) => {
              if (uniqueMediaFilter.includes(mediaName)) {
                accum[mediaName] = base64Data;
              }
              return accum;
            },
            {}
          );

          const payload = {
            wa_endpoint: this.wa_endpoint,
            template,
            messages: toSend,
            media: uniqueMediaDataToSend,
            isStoreInteraction: this.isStoreInteraction,
          };

          if (this.viewMode.id) {
            _.set(payload, "message_id", parseInt(this.viewMode.id));
            _.set(payload, "checkerEmail", this.$store.state.profile.email);
          }

          this.$store
            .dispatch("WHATSAPP_SEND_BULK", {
              data: payload,
            })
            .then(({ result }) => {
              const totalAttemptsNo = result.length;
              const errorAttempts = _.filter(result, ["success", false]);
              const errorTo = _.map(errorAttempts, "to");

              if (errorAttempts.length === 0) {
                this.$message({
                  type: "success",
                  message: `${totalAttemptsNo} of ${totalAttemptsNo} successfully sent!`,
                });
              } else {
                this.$message({
                  type: "warning",
                  message: `${
                    totalAttemptsNo - errorAttempts.length
                  } of ${totalAttemptsNo} successfully sent!`,
                });
                if (errorAttempts.length > 0) {
                  Sentry.captureMessage(`Failed Whatsapp Bulk Send: ${JSON.stringify(errorTo)}`);
                }
              }
              this.sendList = [];
              this.uploadedMessageTemplateMedia = {};
              this.fetchScheduledTask();
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: "An unexpected error has occurred while bulk sending!",
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Bulk send cancelled!",
          });
        });
    },
    sendBulkLater(scheduled = true, needApproval = false) {
      let payload = { needApproval };

      if (scheduled) {
        this.sendTimeFinal = this.sendTime || this.sendTimeFinal;
        this.syncWithSelectedTimezone();
        _.set(payload, "sendTime", this.sendTimeFinal);

        // validation
        if (this.sendTimeFinal && this.sendTimeFinal < Date.now()) {
          this.$alert("Please select later date and time");
          return;
        }
      }

      const [messageNote, popupNote] = this.ignoredNumbersNote(this.noOfIgnored);
      if (
        this.noOfMessageToSendAll <= 0 ||
        (this.noOfMessageToSendNotIgnored <= 0 && !needApproval)
      ) {
        return this.$message({
          type: "error",
          message: `Please select at least 1 recipient for the message template!${messageNote}`,
          duration: 6000,
        });
      }

      const { confirmationPopup, successPopup } = this.getPopupMsg(
        scheduled,
        needApproval,
        this.noOfMessageToSendAll,
        popupNote
      );

      this.$confirm(confirmationPopup, "Warning", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          const template = _.find(
            this.approvedMessageTemplates,
            (o) =>
              o.name === this.selectedSampleMessageTemplate &&
              o.language === this.selectedSampleMessageTemplateLanguage
          );
          const toSend = _.chain(this.sendList)
            .filter((obj) =>
              obj.send && obj.phone_number && (this.forceSend || needApproval)
                ? true
                : !_.includes(this.ignoredNumbers, obj.phone_number)
            )
            .map((obj) => {
              return {
                phone_number: obj.phone_number,
                parameters: _.omit(obj, ["phone_number", "send"]),
              };
            })
            .value();
          const uniqueMediaFilter = _.chain(this.sendList)
            .filter((o) => o.send)
            .map((obj) => obj.hm)
            .compact()
            .uniq()
            .value();

          const uniqueMediaDataToSend = _.reduce(
            this.uploadedMessageTemplateMedia,
            (accum, base64Data, mediaName) => {
              if (uniqueMediaFilter.includes(mediaName)) {
                accum[mediaName] = base64Data;
              }
              return accum;
            },
            {}
          );

          _.merge(payload, {
            formattedMessage: this.formattedSampleMessageTemplateText,
            wa_endpoint: this.wa_endpoint,
            template,
            messages: toSend,
            media: uniqueMediaDataToSend,
            template_id: this.selectedSampleMessageTemplate,
            makerEmail: this.$store.state.profile.email,
            forceSend: this.forceSend,
            isStoreInteraction: this.isStoreInteraction,
          });

          if (this.viewMode.id) {
            _.merge(payload, {
              message_id: parseInt(this.viewMode.id),
              checkerEmail: this.$store.state.profile.email,
            });
          }

          this.$store
            .dispatch("WHATSAPP_SCHEDULE_BULK", {
              data: payload,
            })
            .then((res) => {
              this.fetchScheduledTask();
              this.$message({
                type: "success",
                message: successPopup,
              });
              this.sendList = [];
              this.uploadedMessageTemplateMedia = {};
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: "An unexpected error has occurred while scheduling bulk sending!",
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Schedule bulk send cancelled!",
          });
        });
    },
    cancelBulkMessage(id) {
      this.$store
        .dispatch("WHATSAPP_CANCEL_SCHEDULED_BULK", {
          data: {
            id: id,
          },
        })
        .then((res) => {
          this.fetchScheduledTask();
          this.$message({
            type: "success",
            message: `Scheduled message ${id} was canceled`,
          });
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "An unexpected error has occurred while cancel bulk message!",
          });
        });
    },
    viewBulkMessage(row) {
      const { _id: id } = row;
      this.sendTime = null;
      this.viewMode = { enable: true, id };
      const params = JSON.parse(row.message_parameters);
      this.forceSend = _.get(params, "forceSend", false);
      this.isStoreInteraction = _.get(params, "isStoreInteraction", true);
      this.selectedSampleMessageTemplate = row.template_id;
      this.selectedSampleMessageTemplateLanguage = _.get(params, "template.language");
      this.uploadedMessageTemplateMedia = _.get(params, "media");
      this.changeMessageTemplate();
      this.sendList = params.messages.map((o) => ({
        ...o,
        ...o.parameters,
        send: true,
      }));
    },
    exitViewBulkMessage() {
      this.viewMode = { enable: false, id: "" };
      this.selectedSampleMessageTemplate = "";
      this.selectedSampleMessageTemplateLanguage = "";
      this.uploadedMessageTemplateMedia = {};
      this.selectedMessageTemplate = {};
      this.sendList = [];
      this.$refs.pendingTable?.setCurrentRow();
      this.$refs.checkedTable?.setCurrentRow();
      this.$refs.sentTable?.setCurrentRow();
      this.changeMessageTemplate();
    },
    approveBulkMessage(data) {
      const { maker_email } = data;
      const currentUserEmail = this.$store.state.profile.email;
      if (maker_email === currentUserEmail) {
        this.$message({
          type: "error",
          message: "Cannot approve your own bulk request!",
        });
        return;
      }
      const now = Date.now();
      const send_timestamp = parseInt(data.send_timestamp);
      if (data.send_timestamp && now <= send_timestamp) {
        this.sendTimeFinal = new Date(send_timestamp);
        this.sendBulkLater();
      } else {
        this.sendBulk();
      }
    },
    rejectBulkMessage(data) {
      const { maker_email } = data;
      const currentUserEmail = this.$store.state.profile.email;
      if (maker_email === currentUserEmail) {
        this.$message({
          type: "error",
          message: "Cannot reject your own bulk request!",
        });
        return;
      }
      this.$prompt("Please input the rejection reason", "Rejection Notes", {
        confirmButtonText: "Reject",
        cancelButtonText: "Cancel",
      }).then(({ value: note }) => {
        this.$store
          .dispatch("WHATSAPP_REJECT_SCHEDULED_BULK", {
            data: {
              id: data._id,
              checkerEmail: this.$store.state.profile.email,
              note,
            },
          })
          .then((res) => {
            this.fetchScheduledTask();
            this.$message({
              type: "success",
              message: `Message ${data._id} was rejected`,
            });
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: "An unexpected error has occurred while rejecting bulk message!",
            });
          });
      });
    },
    getPopupMsg(scheduled, needApproval, numberOfMsg, note) {
      const confirmBulkApproval = "You are going to create bulk send request for";
      const successBulkApproval = "You are successfully create bulk send request for";

      let confirmationPopup = `You are going to send ${numberOfMsg} message(s)${note}, are you sure?`;
      let successPopup = `You are success to sending ${this.selectedSampleMessageTemplate}!`;

      if (needApproval) {
        confirmationPopup = `${confirmBulkApproval} ${numberOfMsg} message(s)${note}, continue?`;
        successPopup = `${successBulkApproval} ${this.selectedSampleMessageTemplate}!`;
      }

      if (scheduled) {
        confirmationPopup = `You scheduled to send ${numberOfMsg} message(s)${note} at time ~${this.sendTimeFinal}, are you sure?`;
        successPopup = `You are success to schedule sending ${this.selectedSampleMessageTemplate} at ${this.sendTimeFinal}!`;
      }

      if (scheduled && needApproval) {
        confirmationPopup = `${confirmBulkApproval} ${numberOfMsg} message(s)${note} at time ~${this.sendTimeFinal}, continue?`;
        successPopup = `${successBulkApproval} ${this.selectedSampleMessageTemplate} at ${this.sendTimeFinal}!`;
      }

      return { confirmationPopup, successPopup };
    },
    formatTableTime(time, timestamp = true) {
      let ts = time;
      if (!timestamp) {
        ts = new Date(time).getTime();
      }
      return time
        ? moment
            .unix(ts / 1000)
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format("DD MMM YYYY HH:mm:ss")
        : "-";
    },
    mutateDataTable(data) {
      _.set(data, "req_time", this.formatTableTime(data?.createdAt, false));
      _.set(data, "send_time", this.formatTableTime(data?.send_timestamp));
      _.set(data, "checker_time", this.formatTableTime(data?.checker_timestamp));
    },
    ignoredNumbersNote(number) {
      let res = Array(2).fill("");
      if (number !== 0) {
        res = [
          ` (number list has ${number} ignored number(s))`,
          ` (with ${number} ignored number(s))`,
        ];
      }
      return res;
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/colors.scss";

.upload-demo.hidden-items .el-upload-list.el-upload-list--text {
  display: none;
}
.el-scrollbar__wrap ul {
  width: 100%;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
  color: $color-primary !important;
}
.pointer-row {
  cursor: pointer;
}
.el-upload,
.el-upload-dragger {
  width: 100%;
}
</style>
